(function (angular, undefined) {
    "use strict";
    var links = angular.module("mikesLinks");

    links.config(["$stateProvider", "$urlRouterProvider", function ($stateProvider, $urlRouterProvider) {
        $stateProvider
            .state("links", {
                parent: "mike's",
                url: "/links",
                data: {
                    pageTitle: "Mike's Links"
                },
                views: {
                    "": {
                        templateUrl: "app/links/templates/links.html",
                        controller: "mikesLinks.ctrl",
                        controllerAs: "vm"
                    }
                }
            });
    }]);

})(angular);
